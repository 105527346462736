// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.purchase_sminputContainer__KOwSd {
  /* background-color: red; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 150px;
  min-width: 150px;
  position: relative;
  height: 40px;
}

.purchase_sminput__lRlFb {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 1.5em; /* Add padding to accommodate the icons */
  -moz-appearance: textfield;
}

.purchase_sminput__lRlFb::-webkit-outer-spin-button,
.purchase_sminput__lRlFb::-webkit-inner-spin-button {
  -webkit-appearance: none; /* Hide spinner in Chrome, Safari, Edge, and Opera */
  margin: 0;
}
.purchase_smicon__i\\+1H5 {
  /* position: absolute; */
  /* width: 50px; */
  /* background-color: orange; */
  height: 100%;
  cursor: pointer;
  font-size: 12px;
}

.purchase_smicon__i\\+1H5:first-of-type {
  left: 0.5em;
}

.purchase_smicon__i\\+1H5:last-of-type {
  right: 0.5em;
}

.purchase_add-btn__pCa4V {
  background: rgb(137, 211, 137);
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/admin/screens/purchase_orders/purchase.module.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,sBAAsB;EACtB,gBAAgB,EAAE,yCAAyC;EAC3D,0BAA0B;AAC5B;;AAEA;;EAEE,wBAAwB,EAAE,oDAAoD;EAC9E,SAAS;AACX;AACA;EACE,wBAAwB;EACxB,iBAAiB;EACjB,8BAA8B;EAC9B,YAAY;EACZ,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,8BAA8B;EAC9B,YAAY;AACd","sourcesContent":[".sminputContainer {\n  /* background-color: red; */\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  max-width: 150px;\n  min-width: 150px;\n  position: relative;\n  height: 40px;\n}\n\n.sminput {\n  text-align: center;\n  width: 100%;\n  box-sizing: border-box;\n  padding: 0 1.5em; /* Add padding to accommodate the icons */\n  -moz-appearance: textfield;\n}\n\n.sminput::-webkit-outer-spin-button,\n.sminput::-webkit-inner-spin-button {\n  -webkit-appearance: none; /* Hide spinner in Chrome, Safari, Edge, and Opera */\n  margin: 0;\n}\n.smicon {\n  /* position: absolute; */\n  /* width: 50px; */\n  /* background-color: orange; */\n  height: 100%;\n  cursor: pointer;\n  font-size: 12px;\n}\n\n.smicon:first-of-type {\n  left: 0.5em;\n}\n\n.smicon:last-of-type {\n  right: 0.5em;\n}\n\n.add-btn {\n  background: rgb(137, 211, 137);\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sminputContainer": `purchase_sminputContainer__KOwSd`,
	"sminput": `purchase_sminput__lRlFb`,
	"smicon": `purchase_smicon__i+1H5`,
	"add-btn": `purchase_add-btn__pCa4V`
};
export default ___CSS_LOADER_EXPORT___;
