import React, {useEffect} from "react";
import styles from "../../../styles/admin.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBuilding, faClipboard, faPerson, faPersonBiking} from "@fortawesome/free-solid-svg-icons";
import {formatTimestamp, getOrderStatus} from "../../../components/utils";
import {toast, ToastTypes} from "../../../../components/ajonjolib/toasts/toast/toast";
import {formatPrice} from "../../../../utils/utils";

export default function SaleDetailModal({data}) {
    const [variantTotal, setVariantTotal] = React.useState(0);
    const clipboardMessage = () => {
        navigator.clipboard.writeText(`Nom: ${data?.user?.user_data?.name + ' ' + data?.user?.user_data?.lastname}\nAddress: ${data?.address}\nMap: https://www.google.com/maps/place/${data?.latitude},${data?.longitude}\nIndications: ${data.indications}\nQuartier: ${data?.district?.name}\nTéléphone: ${data.user.user_data?.phone_number}\nTotal: ${formatPrice(data.sub_total + data.fee)}\nLivraison: ${data.fee}`);
        toast("Copied to clipboard", ToastTypes.SUCCESS);
    }

    useEffect(() => {
        console.log(data);
        let total_variant_price = 0;
        data.items.forEach((item) => {
            let item_variant_price = 0;
            item.variant_items.forEach((variant) => {
                item_variant_price += variant.variant_item.price * item.qty;
            });
            item.variant_cost = item_variant_price;
            total_variant_price += item_variant_price;
        });
        console.log(total_variant_price);
        setVariantTotal(total_variant_price);
    }, [data]);

    return (
        <div>
              <div className={`${styles.createContainer} p-4`}>
                  <div className={'d-flex'}>
                      <div style={{textAlign: 'left', flexBasis: '50%'}} className={'d-flex'}>
                          <FontAwesomeIcon icon={faBuilding} size={"2xl"} className={'pe-3'} color={'#777'}/>
                          <div>
                              <div className={'mb-2'}><b>{data.supplier ? data.supplier?.name : data.warehouse?.name}</b></div>
                          </div>
                      </div>
                      <div style={{textAlign: 'left', flexBasis: '50%'}} className={'d-flex'}>
                          <FontAwesomeIcon icon={faPerson} size={"2xl"} className={'pe-3'} color={'#777'}/>
                          <div>
                              <div>
                                  <div className={'mb-2'}><b>Client</b></div>
                                  <div>Name: {data.user.user_data?.name + ' ' + data.user.user_data?.lastname}</div>
                                  <div>Email: {data.user.email}</div>
                                  <div>Phone: {data.user.user_data?.phone_number}</div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className={`${styles.createContainer} p-4`}>
                  <div className={'d-flex'}>
                      <div style={{textAlign: 'left', flexBasis: '50%'}} className={'d-flex'}>
                          <FontAwesomeIcon icon={faClipboard} size={"2xl"} className={'pe-3'} color={'#777'}/>
                          <div>
                              <div className={'mb-2'}><b>Reference: {data.reference}</b></div>
                              <div className={'mb-2'}><b>Date: {formatTimestamp(data.date)}</b></div>
                              <div className={'mb-2'}><b>Status: {getOrderStatus(data.status)}</b></div>
                          </div>
                      </div>
                      <div style={{textAlign: 'left', flexBasis: '50%'}} className={'d-flex'}>
                          <FontAwesomeIcon icon={faPersonBiking} size={"2xl"} className={'pe-3'} color={'#777'}/>
                          <div>
                              <div className={'mb-2'}><b>Address: {data?.address}</b></div>
                              <div className={'mb-2'}><b>District: {data?.district?.name}</b></div>
                              <div className={'mb-2'}><b>Indications: {data?.indications}</b></div>
                              <div className={'d-flex align-items-center'} role={'button'} onClick={clipboardMessage}>
                                  <FontAwesomeIcon icon={faClipboard} color={'#777'}/>
                                  <div className={'ps-2'}><b>Copy Message</b></div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className={'d-flex justify-content-center mt-3'}>
                      <table className="table table-bordered table-hover">
                          <thead>
                          <tr>
                              <th>Product</th>
                              <th>Quantity</th>
                              <th>Unit Cost</th>
                              <th>Total</th>
                          </tr>
                          </thead>
                          <tbody>
                          {data.items?.map((item, index) => {
                              if(!item.is_combo) {
                                  return (
                                      <tr key={index}>
                                          <td>{item.product_name} ({item.variant_items.map((variant, index) => {
                                              return (
                                                  <span key={index}><b>{variant.variant_name}</b> : {variant.variant_item.name}, </span>
                                              )
                                          })})</td>
                                          <td>{item.qty}</td>
                                          <td>{formatPrice(item.price + (item.variant_cost ? (item.variant_cost / item.qty) : 0))}</td>
                                          <td>{formatPrice(item.sub_total + (item.variant_cost ? item.variant_cost : 0))}</td>
                                      </tr>
                                  );
                              }

                              return item.combo_items.map((combo_item, index) => {
                                  return (
                                      <tr key={index}>
                                          <td>{combo_item.product_name}</td>
                                          <td>{combo_item.quantity * item.qty}</td>
                                          <td>{formatPrice(combo_item.price)}</td>
                                          <td>{formatPrice(combo_item.price * combo_item.quantity * item.qty)}</td>
                                      </tr>
                                  )
                              });
                          })}
                          <tr>
                              <td colSpan={3} className={'text-end'}><b>Delivery Fee</b></td>
                              <td>{formatPrice(data.fee)}</td>
                          </tr>
                          <tr>
                              <td colSpan={3} className={'text-end'}><b>Total</b></td>
                              <td>{formatPrice(parseInt(data.sub_total + data.fee + variantTotal))}</td>
                          </tr>
                          </tbody>
                      </table>
                  </div>
            </div>
        </div>
    );
}