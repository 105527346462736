import React, { useContext, useEffect, useState } from "react";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import styles from "../../styles/admin.module.css";
import TableView from "../../components/table_view";
import {
  faBarChart,
  faClipboard,
  faDownload,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../../AxiosInstance";
import myContext from "../../../MyContext";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../components/delete_modal/delete_modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatPrice } from "../../../utils/utils";
import {
  ToastTypes,
  toast,
} from "../../../components/ajonjolib/toasts/toast/toast";

export default function BillersList() {
  const [rows, setRows] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const navigate = useNavigate();
  const editObj = (data) => {
    navigate("/admin/billers/create", { state: data });
  };

  const stats = (data) => {
    navigate("/admin/biller/stats", { state: data });
  };

  const deleteObj = (id) => {
    axiosInstance.delete("supplier/" + id + "/").then((response) => {
      window.location.reload();
    });
  };
  const downloadPreviousMonthExcel = (id, name, commissionAmount) => {
    const currentDate = new Date();
    const previousMonth = currentDate.getMonth(); // 0-based index
    const year = currentDate.getFullYear();
    const month = previousMonth === 0 ? 12 : previousMonth;
    const currentYear = previousMonth === 0 ? year - 1 : year;

    axiosInstance
      .get(`supplier/${id}/excel/?month=${month}&year=${currentYear}`, {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${name}-${month}-${currentYear}-commission-${formatPrice(
            commissionAmount
          )}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      });
  };
  const copyToClipboard = async (data) => {
    const currentDate = new Date();
    const previousMonth = currentDate.getMonth(); // 0-based index
    const monthNames = [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ];
    const month = previousMonth === 0 ? 12 : previousMonth;
    const monthName = monthNames[month - 1];
    const year = currentDate.getFullYear();
    const currentYear = previousMonth === 0 ? year - 1 : year;

    try {
      const response = await axiosInstance.get(`supplier/${data.id}/stats/`);
      const salesData = response.data.sales_by_month;
      const total = salesData[month]?.total_sales || 0;
      const commissionAmount = total * (data.commission / 100);

      const text = `Bonjour ${
        data.name
      }, voici le point de vente du dernier mois :\n${monthName} ${currentYear} :  ${formatPrice(
        total
      )} F CFA, \nCommission : ${formatPrice(
        commissionAmount
      )} F CFA \nBonne réception !`;

      navigator.clipboard.writeText(text).then(
        () => {
          toast("Text copied to clipboard", ToastTypes.SUCCESS);
        },
        (err) => {
          console.error("Could not copy text: ", err);
        }
      );
    } catch (error) {
      console.error("Error fetching sales data:", error);
    }
  };

  const handleDownload = async (data) => {
    const currentDate = new Date();
    const previousMonth = currentDate.getMonth(); // 0-based index
    const year = currentDate.getFullYear();
    const month = previousMonth === 0 ? 12 : previousMonth;
    const currentYear = previousMonth === 0 ? year - 1 : year;

    try {
      const response = await axiosInstance.get(`supplier/${data.id}/stats/`);
      const salesData = response.data.sales_by_month;
      const commissionAmount = salesData[month]?.total_sales
        ? salesData[month].total_sales * (data.commission / 100)
        : 0;

      downloadPreviousMonthExcel(data.id, data.name, commissionAmount);
    } catch (error) {
      console.error("Error fetching sales data:", error);
    }
  };

  const cols = [
    { title: "ID", internal: "id" },
    { title: "Image", image: { url: "image.image" } },
    { title: "Name", internal: "name", filter: true },
    { title: "Description", internal: "description" },
    { title: "Active", internal: "active", isBoolean: true },
    {
      title: "Month Sales",
      internal: "last_month_sales",
      code: (row, data) => (data ? formatPrice(data) : ""),
    },
    {
      title: "Commission",
      internal: "commission",
      code: (row, data) => {
        return row.commission + "%";
      },
    },
    {
      title: "Statut",
      internal: "payment_status",
      code: (row, data) => {
        const color = data === "Payé" ? "#4CAF50" : "#EF4444";
        return <span style={{ color: color, fontWeight: "bold" }}>{data}</span>;
      },
    },
    {
      title: "Actions",
      internal: "id",
      code: (data, row) => {
        return (
          <div className={"d-flex justify-content-end"}>
            <div
              role={"button"}
              onClick={() => editObj(data)}
              className={"pe-3"}
            >
              <FontAwesomeIcon icon={faPenToSquare} color={"#9EA3AC"} />
            </div>

            <div role={"button"} onClick={() => stats(data)} className={"pe-3"}>
              <FontAwesomeIcon icon={faBarChart} color={"#9EA3AC"} />
            </div>

            <div
              role={"button"}
              onClick={() => {
                setShowDelete(true);
                setSelectedId(row);
              }}
              className={"pe-3"}
            >
              <FontAwesomeIcon icon={faTrash} color={"#EF4444"} />
            </div>
            <div
              role={"button"}
              onClick={() => handleDownload(data)}
              className="pe-3"
            >
              <FontAwesomeIcon icon={faDownload} color={"#4CAF50"} />
            </div>
            <div role={"button"} onClick={() => copyToClipboard(data)}>
              <FontAwesomeIcon icon={faClipboard} color={"#00BFFF"} />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />

      <DeleteModal
        show={showDelete}
        setter={setShowDelete}
        onClick={() => deleteObj(selectedId)}
      />

      <div className={`${styles.container} me-4 pb-4`}>
        <TableView
          fetchUrl={"supplier"}
          defaultFilters={{ sale_info: true }}
          pull={setRows}
          noPagination={true}
          title={"billers"}
          cols={cols}
          rows={rows}
          withIndex={true}
        />
      </div>
    </div>
  );
}
