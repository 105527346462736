import React, { useEffect, useState } from "react";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import styles from "../../styles/admin.module.css";
import {
  PrimaryButton,
  SecondaryButton,
  SelectInput,
} from "../../components/inputs";
import axiosInstance from "../../../AxiosInstance";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faClipboard,
  faTruck,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import { formatTimestamp } from "../../components/utils";
import { formatPrice } from "../../../utils/utils";

export default function PurchaseOrderView() {
  const { state } = useLocation();
  const [data, setData] = useState({});
  const [form, setForm] = useState({});
  const permissions = parseInt(localStorage.getItem("permissions"));

  useEffect(() => {
    axiosInstance.get(`purchase_order/${state.id}/`).then((response) => {
      setData(response.data);
      setForm({
        status: parseInt(state.status),
        payed: state.payed ? 1 : 0,
        products: response.data.products.map((product) => ({
          ...product,
          name: product.product.name,
          quantity: product.quantity, // Assurez-vous que `quantity` a une valeur par défaut
          packaging: product.packaging,
          total: product.subtotal,
          originalPackaging: product.product.packaging,
          cost: parseInt(product.subtotal) / product.quantity,
        })),
      });
    });
  }, []);

  const back = () => {
    window.location.replace("/admin/purchase_order");
  };

  const getStatus = (status) => {
    if (status === 0) return "Pending";
    else if (status === 1) return "In Process";
    else if (status === 2) return "Received";
    else return "Cancelled";
  };

  const update = () => {
    axiosInstance.put(`purchase_order/${state.id}/`, form).then((response) => {
      back();
    });
    // console.log(form);
  };

  const options = [
    { id: 0, name: "Pending" },
    { id: 1, name: "In Process" },
    { id: 2, name: "Received" },
    { id: 3, name: "Cancelled" },
  ];
  const filteredOptions =
    data?.status === 0 ? options : options.filter((option) => option.id !== 3);

  console.log(data?.status);
  console.log(filteredOptions);

  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />

      <div className={`${styles.crudContainer}`}>
        <div className={styles.crudText}>
          <div className={"mb-2"}>Purchase Order</div>
          <div></div>
        </div>
        <div className={styles.crudInputs}>
          <div className={`${styles.createContainer} p-4`}>
            <div className={"d-flex"}>
              <div
                style={{ textAlign: "left", flexBasis: "33%" }}
                className={"d-flex"}
              >
                <FontAwesomeIcon
                  icon={faBuilding}
                  size={"2xl"}
                  className={"pe-3"}
                  color={"#777"}
                />
                <div>
                  <div className={"mb-2"}>
                    <b>{data?.biller?.name}</b>
                  </div>
                  <div>Contact: {data?.biller?.contact_name}</div>
                  <div>Email: {data?.biller?.contact_email}</div>
                  <div>Phone: {data?.biller?.contact_number}</div>
                </div>
              </div>
              <div
                style={{ textAlign: "left", flexBasis: "33%" }}
                className={"d-flex"}
              >
                <FontAwesomeIcon
                  icon={faTruck}
                  size={"2xl"}
                  className={"pe-3"}
                  color={"#777"}
                />
                <div>
                  <div className={"mb-2"}>
                    <b>{data?.warehouse?.name}</b>
                  </div>
                </div>
              </div>
              <div
                style={{ textAlign: "left", flexBasis: "33%" }}
                className={"d-flex"}
              >
                <FontAwesomeIcon
                  icon={faClipboard}
                  size={"2xl"}
                  className={"pe-3"}
                  color={"#777"}
                />
                <div>
                  <div className={"mb-2"}>
                    <b>Reference: </b>
                    {data.reference}
                  </div>
                  <div>
                    <b>Date: </b>
                    {formatTimestamp(data.timestamp)}
                  </div>
                  <div>
                    <b>Status: </b>
                    {getStatus(data.status)}
                  </div>
                  <div>
                    <b>Payed: </b>
                    {data.payed ? "Yes" : "No"}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.createContainer} p-4`}>
            <div style={{ width: "100%" }} className={"d-flex mb-4"}>
              {permissions === 2 ? null : (
                <div style={{ flexBasis: "50%" }} className={"pe-3"}>
                  <SelectInput
                    name={"status"}
                    form={form}
                    setForm={setForm}
                    options={filteredOptions}
                  />
                </div>
              )}
              <div style={{ flexBasis: "50%" }} className={"pe-3"}>
                <SelectInput
                  name={"payed"}
                  form={form}
                  setForm={setForm}
                  options={[
                    { id: 0, name: "No" },
                    { id: 1, name: "Yes" },
                  ]}
                />
              </div>
            </div>
            <div className={"d-flex justify-content-center"}>
              <table className="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Product</th>
                    <th>Packaging</th>
                    <th>Quantity</th>
                    <th>Unit Cost</th>
                    <th>Expiry Date</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {data.products?.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.product.name}</td>
                      <td>{item.packaging}</td>
                      <td>{item.quantity}</td>
                      <td>{formatPrice(item.subtotal / item.quantity)}</td>
                      <td>{item.expiry_date}</td>
                      <td>{item.subtotal}</td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={6} className={"text-end"}>
                      <b>Total</b>
                    </td>
                    <td>{data.total}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className={styles.buttonContainer}>
            <SecondaryButton name={"Back"} onClick={back} />
            <div className={"ps-3"} />
            <PrimaryButton name={"Update"} onClick={update} />
          </div>
        </div>
      </div>
    </div>
  );
}
