import React, {useState} from "react";
import styles from "../../styles/admin.module.css";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import TableView from "../../components/table_view";

export default function SubcategoryAlerts() {
    const [rows, setRows] = useState([]);

    const cols = [
        { title: "Product", internal: "name"},
        { title: "Supplier", internal: "biller.name" },
        { title: "Biller", internal: "supplier.name" },
    ]

    return (
        <div className={styles.mainContainer}>
            <AdminHeader/>
            <AdminSidebar/>

            <div className={`${styles.container} me-4`}>
                <TableView
                    fetchUrl={'stock/subcategory_alert'}
                    pull={setRows}
                    title={'Subcategory Alerts'}
                    cols={cols}
                    rows={rows}
                    noCreate={true}
                />
            </div>
        </div>
    )
}