import React from "react";
import styles from './confirmation.module.css';
import ShopHeader from "../components/header/header";
import {useLocation} from "react-router-dom";
import {formatPrice} from "../../utils/utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";

export default function Confirmation() {
    const {state} = useLocation();

    const next = () => {
        window.location.replace('/');
    }

    return (
        <div className={styles.container}>
            <ShopHeader/>

            <div className={styles.boxContainer}>
                <div>Résumé de commande</div>

                <div className={'mt-2 mb-4'}>
                    <div className={'fw-bolder'} style={{fontWeight: '1.4rem'}}>Merci {state.userData?.user_data?.name} {state.userData?.user_data?.lastname} !</div>
                    <FontAwesomeIcon color={'#46b38c'} icon={faCheck} size={'5x'}/>
                    <div>Votre commande est en préparation!</div>
                </div>

                <div className={'mb-4'}>Merci de garder votre téléphone près de vous pour assurer le bon déroulement de notre service!</div>

                <div>Total: {formatPrice(state.total + state.deliveryFee)} FCFA</div>

                <div className={`${styles.orderButton} pe-2 ps-4 mt-3`} onClick={() => next()}>
                    <div className={'px-5 py-2'}>Continuer</div>
                </div>
            </div>
      </div>
    );
}