import React, { useEffect, useState } from "react";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import styles from "../../styles/admin.module.css";
import TableView from "../../components/table_view";
import axiosInstance from "../../../AxiosInstance";
import { formatTimestamp } from "../../components/utils";

export default function SalesProducts() {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    axiosInstance.get("sale_item/").then((response) => {
      setRows(response?.data?.results);
    });
  }, []);

  const cols = [
    { title: "Reference", internal: "sale_reference", filter: true },
    {
      title: "Date",
      internal: "timestamp",
      filter: true,
      dateFilter: true,
      code: (data, row) => formatTimestamp(row),
    },
    { title: "Product", internal: "product_name", filter: true },
    { title: "Warehouse", internal: "warehouse_name", filter: true },
    { title: "Biller", internal: "biller_name", filter: true },
    {
      title: "Client",
      internal: "client_name",
      filter: true,
      filterInternal: "client_name",
    },
    { title: "Phone Number", internal: "client_phone_number", filter: true },
    { title: "Quantity", internal: "qty", filter: true },
    { title: "Cost", internal: "cost", filter: true },
    { title: "Price", internal: "price", filter: true },
    {
      title: "Profit",
      internal: "price",
      code: (row) => {
        return row.price - row.cost;
      },
    },
    { title: "Total", internal: "sub_total", filter: true },
  ];

  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />

      <div className={`${styles.container} me-4`}>
        <TableView
          noCreate={true}
          fetchUrl={"sale_item"}
          title={"sale Item"}
          pull={setRows}
          cols={cols}
          rows={rows}
        />
      </div>
    </div>
  );
}
