import React, {useEffect, useState} from "react";
import styles from "./large_product_card.module.css";
import {formatPrice} from "../../../utils/utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faX} from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../../AxiosInstance";
import {useLocation} from "react-router-dom";
import {toast, ToastTypes} from "../../../components/ajonjolib/toasts/toast/toast";

export default function LargeProductCard({product, biller, onClick, cartItems, setRefreshCart, setShowLogin}) {
    const [quantity, setQuantity] = useState(0);

    const {state} = useLocation();

    const addToCart = () => {
        if (!biller?.is_open) {
            toast(`${biller.name} actuellement fermé`, ToastTypes.ERROR);
            return;
        }
        if(product.variants.length === 0)
        {
            axiosInstance.post(`cart/add/${product.id}/`, {
                quantity: 1,
                variants: [],
                restaurant_id: state?.id
            }).then((response) => {
                setRefreshCart((prev) => prev + 1);
            });
            setRefreshCart((prev) => prev + 1);
        }
        else {
            onClick();
        }
    }

    useEffect(() => {
        let found = false;
        let foundQuantity = 0;
        cartItems?.filter(item => item.product.id === product.id).map(item => {
            foundQuantity += item.qty
            found = true;
        });
        if(!found) {
            cartItems?.filter(item => item.product.id === product.id).map(item => {
                foundQuantity += item.qty
                found = true;
            });
        }
        setQuantity(foundQuantity);
    }, [cartItems]);

    const modifyQuantity = (new_quantity) => {
        if (!biller?.is_open) {
            toast(`${biller.name} actuellement fermé`, ToastTypes.ERROR);
            return;
        }
        const filtered_items = cartItems?.filter(item => item.product.id === product.id).map((item) => item.id);
        filtered_items.sort((a, b) => a - b);
        filtered_items.reverse();
        axiosInstance.post(`cart/add/${product.id}/`, {
            quantity: new_quantity,
            is_restaurant: true,
            item_id: filtered_items[0]
        }).then((response) => {
            let found = false;
            let foundQuantity = 0;
            response.data?.items?.filter(item => item.product.id === product.id).map(item => {
                foundQuantity += item.qty
                found = true;
            });
            if(!found) {
                response.data?.items?.filter(item => item.product.id === product.id).map(item => {
                    foundQuantity += item.qty
                    found = true;
                });
            }
            setQuantity(foundQuantity);
            setRefreshCart((prev) => prev + 1);
        });
    }

    return (
        <div style={{position: "relative"}}>
            <div className={styles.container} onClick={onClick}>
                <div className={styles.productInfo}>
                    <div className={styles.productName}>{product.name}</div>
                    <div className={'me-2'} style={{textOverflow: 'ellipsis', overflow: 'hidden'}}>{product.description}</div>
                    <div className={styles.productPrice}>{formatPrice(product.price)} FCFA</div>
                </div>
                <img
                    src={product?.image ? product.image.image : '/placeholder.jpg'}
                    alt={product.name}
                    className={styles.productImage}
                />
            </div>

            {quantity === 0 ?
                <div className={styles.addButton} onClick={() => {
                    if (!biller?.is_open) {
                        toast(`${biller.name} actuellement fermé`, ToastTypes.ERROR);
                        return;
                    }
                    const hasVariants = product.variants.length > 0;
                    if (hasVariants) {
                        addToCart();
                        return;
                    }

                    axiosInstance.post(`cart/add/${product?.id}/`, {
                        "quantity": 1,
                        "variants": [],
                        "restaurant_id": state?.id
                    }).then((response) => {
                        if(response?.response?.status === 500) {
                            setShowLogin((prev) => prev + 1);
                        }
                        setRefreshCart((prev) => prev + 1);
                    })
                    setRefreshCart((prev) => prev + 1);
                }}>
                    <FontAwesomeIcon icon={faPlus}/>
                </div>
                :
                <div className={`${styles.quantityButtons}`}>
                    <div className={`${styles.quantityPlusMinus} ps-2`} style={{textAlign: 'left'}} onClick={() => modifyQuantity(-1)}>-</div>
                    <div>{quantity}</div>
                    <div className={`${styles.quantityPlusMinus} pe-2`} style={{textAlign: 'right'}} onClick={() => {
                        const hasVariants = product.variants.length > 0;
                        if (hasVariants) {
                            addToCart();
                            return;
                        }
                        modifyQuantity(1);
                    }}>+</div>
                </div>
            }
        </div>
    )
}