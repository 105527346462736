import React, { useContext, useEffect, useState } from "react";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import styles from "../../styles/admin.module.css";
import TableView from "../../components/table_view";
import axiosInstance from "../../../AxiosInstance";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import myContext from "../../../MyContext";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../components/delete_modal/delete_modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDateToDayMonthYear } from "../../../utils/utils";

export default function List() {
  const [rows, setRows] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const { setEditing, setProduct } = useContext(myContext);
  const navigate = useNavigate();

  const deleteObj = (id) => {
    axiosInstance.delete("promotion/" + id + "/").then((response) => {
      window.location.reload();
    });
  };

  const editObj = (data) => {
    setEditing(data);
    setProduct(data);
    navigate("/admin/promotions/create");
  };

  const cols = [
    { title: "Product", internal: "product.name" },
    { title: "Active", internal: "active", isBoolean: true },
    {
      title: "Type",
      internal: "type",
      code: (row, data) => {
        return data === 0 ? "Percentage" : "Price";
      },
    },
    { title: "Value", internal: "value" },
    // {title: 'Start Date', internal: 'start_date'},
    // {title: 'End Date', internal: 'end_date'},
    {
      title: "Start Date",
      internal: "start_date",
      code: (row, data) => formatDateToDayMonthYear(data),
    },
    {
      title: "End Date",
      internal: "end_date",
      code: (row, data) => formatDateToDayMonthYear(data),
    },
    { title: "Units Sold", internal: "units_sold" },
    { title: "Max Units", internal: "max_units" },
    { title: "Ngor", internal: "product.stock_1" },
    { title: "Plateau", internal: "product.stock_2" },
    { title: "Point E", internal: "product.stock_3" },
    {
      title: "Actions",
      internal: "id",
      code: (data, row) => {
        return (
          <div className={"d-flex justify-content-end"}>
            <div
              role={"button"}
              onClick={() => {
                editObj(data);
              }}
              className={"pe-3"}
            >
              <FontAwesomeIcon icon={faPenToSquare} color={"#9EA3AC"} />
            </div>

            <div
              role={"button"}
              onClick={() => {
                setShowDelete(true);
                setSelectedId(row);
              }}
            >
              <FontAwesomeIcon icon={faTrash} color={"#EF4444"} />
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    setEditing(0);
    axiosInstance.get("promotion/").then((response) => {
      setRows(response.data);
    });
  }, []);

  return (
    <div>
      <AdminHeader />
      <AdminSidebar />

      <DeleteModal
        show={showDelete}
        setter={setShowDelete}
        onClick={() => deleteObj(selectedId)}
      />

      <div className={`${styles.container} me-4`}>
        <TableView
          fetchUrl={"promotion"}
          title={"promotions"}
          cols={cols}
          rows={rows}
          noCreate={true}
          withImport={false}
          pull={setRows}
          noPagination={true}
        />
      </div>
    </div>
  );
}
