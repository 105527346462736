import React, { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import ShopHeader from "../components/header/header";
import PageTitle from "../components/page_title/page_title";
import DeliveryText from "../components/delivery_text/delivery_text";
import axiosInstance from "../../AxiosInstance";
import styles from "../products/products.module.css";
import ProductCard from "../components/product_card/product_card";
import Loading from "../../admin/components/loading";
import Cart from "../components/cart/cart";
import ProductModal from "../components/product_modal/product_modal";
import LargeProductCard from "../components/large_product_card/large_product_card";
import { PrimaryButton, TextInput } from "../../admin/components/inputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faX } from "@fortawesome/free-solid-svg-icons";
import Text from "../../components/ajonjolib/inputs/text/text";
import {
  toast,
  ToastTypes,
} from "../../components/ajonjolib/toasts/toast/toast";

export default function ShopBillerProducts() {
  const { biller_slug } = useParams();
  const { state } = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [products, setProducts] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [loading, setLoading] = useState(true);
  const [currentSubcategory, setCurrentSubcategory] = useState(null);
  const [subcategories, setSubcategories] = useState([]);
  const [refreshCart, setRefreshCart] = useState(0);
  const [selectValues, setSelectValues] = useState([]);
  const [biller, setBiller] = useState({});
  const scRefs = useRef([]);
  const district = JSON.parse(localStorage.getItem("district"));
  const [form, setForm] = useState({});
  const [isSticky, setIsSticky] = useState(false);
  const [variantErrorId, setVariantErrorId] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [scrollLock, setScrollLock] = useState(false);
  const [showLogin, setShowLogin] = useState(0);
  const [lockScrollSubcategory, setLockScrollSubcategory] = useState(false);
  const currentSubcategoryRef = useRef(null);
  const getEverything = () => {
    axiosInstance
      .get(`subcategory/?biller_id=${biller?.id}`)
      .then((response) => {
        const subcategory_results = response?.data?.results;
        setSubcategories(subcategory_results);
        if (subcategory_results.length > 0) {
          setCurrentSubcategory(subcategory_results[0]);

          let mult_subcategoryQuery = "";
          subcategory_results.map((subcategory) => {
            mult_subcategoryQuery += `&mult_subcategory=${subcategory.id}`;
          });

          axiosInstance
            .get(
              `product/?max_query=1000&only_supplier=1&shop=1${mult_subcategoryQuery}`
            )
            .then((new_response) => {
              const groupedProducts = subcategory_results.map(
                (subcategory) => ({
                  subcategory,
                  products: [],
                })
              );

              new_response?.data?.results.forEach((product) => {
                const subcategory = product.subcategory;
                const groupIndex = groupedProducts.findIndex(
                  (g) => g.subcategory.id === subcategory.id
                );

                if (groupIndex !== -1) {
                  groupedProducts[groupIndex].products.push(product);
                }
              });

              setProducts(groupedProducts);

              setLoading(false);
            });
        }
      });
  };

  useEffect(() => {
    if (currentSubcategoryRef.current) {
      const isVisible = isElementVisible(currentSubcategoryRef.current);
      if (!isVisible) {
        // Vous pouvez faire défiler jusqu'à l'élément si ce n'est pas visible
        currentSubcategoryRef.current.scrollIntoView({
          behavior: "smooth",
          inline: "center",
        });
      }
    }
  }, [currentSubcategory]);

  const isElementVisible = (element) => {
    if (!element) return false;
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  useEffect(() => {
    document.title = "Products | Bring Me";
    setSubcategories([]);

    setLoading(true);
    if (state) setBiller(state);
    else {
      axiosInstance
        .get(`supplier/get_by_slug/?slug=${biller_slug}`)
        .then((response) => {
          setBiller(response?.data);
        });
    }
  }, []);

  useEffect(() => {
    if (Object.keys(biller).length > 0) getEverything();
  }, [biller]);

  useEffect(() => {
    if (scrollLock) {
      setTimeout(function () {
        setScrollLock(false);
      }, 1500);
    }
  }, [scrollLock]);
  //new
  const scrollToSubcategory = (subcategory) => {
    const ref = scRefs.current[subcategory.id];
    if (ref) {
      setScrollLock(true);
      const headerHeight = 160; // Assuming the header height is 80 pixels
      ref.scrollIntoView({
        behavior: "instant",
        block: "start",
        inline: "nearest",
      });
      window.scrollBy(0, -headerHeight);
      //ref.scrollIntoView({ behavior: 'instant' });
    }
  };
  // removed
  // useEffect(() => {
  //   if (currentSubcategory) {
  //     setScrollLock(true);
  //     scRefs.current[currentSubcategory.id]?.scrollIntoView({
  //       behavior: "instant",
  //     });
  //   }
  // }, [currentSubcategory]);
  // new
  useEffect(() => {
    if (currentSubcategory !== null && !lockScrollSubcategory) {
      scrollToSubcategory(currentSubcategory);
    }
    if (lockScrollSubcategory) {
      setLockScrollSubcategory(false);
    }
  }, [currentSubcategory]);

  const checkQuantity = (newQuantity) => {
    if (newQuantity < 0 && quantity === 1) return;
    setQuantity((prevQuantity) => prevQuantity + newQuantity);
  };

  const addToCart = () => {
    // Variant verification
    let canAdd = true;
    if (selectedProduct.variants.length > 0) {
      selectedProduct.variants.forEach((variant) => {
        if (variant.min_quantity > 0) {
          const found = variant.items.filter((item) =>
            selectValues.includes(item.id)
          );
          if (found.length < variant.min_quantity) {
            setVariantErrorId(variant.id);
            canAdd = false;
            toast(
              "Merci de remplir tous les champs obligatoires.",
              ToastTypes.ERROR
            );
          }
        }
      });
    }
    if (!canAdd) return;

    setVariantErrorId(null);

    // Add to Cart
    axiosInstance
      .post(`cart/add/${selectedProduct.id}/`, {
        quantity: quantity,
        variants: selectValues,
        restaurant_id: state?.id,
      })
      .then((response) => {
        if (response?.response?.status === 500) {
          setShowLogin((prev) => prev + 1);
        }
        /*setShowModal(false);
            setQuantity(1);
            setRefreshCart((prev) => prev + 1);*/
        setRefreshCart((prev) => prev + 1);
      });
    setShowModal(false);
    setQuantity(1);
    setRefreshCart((prev) => prev + 1);
  };

  const isElementInViewport = (el) => {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  const handleScroll = () => {
    const scrollY = window.scrollY;
    setIsSticky(scrollY > 0);

    if (scrollLock) return;

    const visibleSubcategory = subcategories.find((subcategory) => {
      const ref = scRefs.current[subcategory.id];
      if (ref) {
        const rect = ref.getBoundingClientRect();
        return (
          rect.top <= window.innerHeight / 2 &&
          rect.bottom >= window.innerHeight / 2
        );
      }
      return false;
    });

    if (visibleSubcategory && currentSubcategory !== visibleSubcategory) {
      setLockScrollSubcategory(true);
      setCurrentSubcategory(visibleSubcategory);
    }
  };

  const debouncedHandleScroll = (() => {
    let timeoutId;

    return () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(handleScroll, 200);
    };
  })();

  useEffect(() => {
    window.addEventListener("scroll", debouncedHandleScroll);
    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll);
    };
  }, [subcategories, currentSubcategory, scrollLock]);

  return (
    <div>
      <ShopHeader showLogin={showLogin} />
      <Cart refreshCart={refreshCart} outCartItems={setCartItems} />

      <ProductModal
        locked={false}
        variantErrorId={variantErrorId}
        biller={biller}
        selectValues={selectValues}
        setSelectValues={setSelectValues}
        quantity={quantity}
        setShowModal={setShowModal}
        showModal={showModal}
        selectedProduct={selectedProduct}
        addToCart={addToCart}
        checkQuantity={checkQuantity}
      />

      <div className={`pb-5`} style={{ paddingTop: "80px" }}>
        <PageTitle name={state?.name} description={state?.description} />
        <div
          className={`${styles.scrollable} ${
            isSticky ? styles.stickyCategoryBar : ""
          }`}
        >
          {subcategories?.map((subcategory, index) => (
            <div
              ref={
                currentSubcategory?.id === subcategory.id
                  ? currentSubcategoryRef
                  : null
              }
              className={`px-3 py-2 ${styles.scrollableItem} ${
                currentSubcategory?.id === subcategory.id && styles.selected
              }`}
              onClick={() => setCurrentSubcategory(subcategory)}
            >
              <div>{subcategory.name}</div>
            </div>
          ))}
        </div>

        <PageTitle>
          <div
            className={"d-flex justify-content-center mt-2 align-items-center"}
          >
            <div className={`${styles.responsiveSearch} me-2`}>
              <Text
                style={{ width: "100%" }}
                value={form["query"]}
                onChange={(e) => setForm({ ...form, query: e })}
                placeholder={"Recherchez vos produits à partir d'ici"}
              />
            </div>
            <div style={{ cursor: "pointer" }}>
              <FontAwesomeIcon
                icon={faX}
                size={"lg"}
                color={"#00A6A8"}
                onClick={() => setForm({ ...form, query: "" })}
              />
            </div>
          </div>
        </PageTitle>

        <div className={`${styles.responsiveContainer}`}>
          <DeliveryText />

          {loading ? (
            <Loading />
          ) : (
            <div className={"d-flex flex-column justify-content-center"}>
              {products
                ?.filter((group) => group.products.length > 0)
                ?.map((group) => (
                  <div
                    key={group?.subcategory.id}
                    className={"mb-4"}
                    ref={(ref) => (scRefs.current[group?.subcategory.id] = ref)}
                  >
                    <div className={"pb-1 fw-bold fs-2 text-start"}>
                      {group?.subcategory.name}
                    </div>
                    <div className={`${styles.responsiveFlex}`}>
                      {group.products
                        .filter((obj) =>
                          obj.name
                            .toLowerCase()
                            .includes(
                              form["query"] ? form["query"].toLowerCase() : ""
                            )
                        )
                        .map((product, index2) => (
                          <div
                            key={product.id}
                            className={`mb-2 ${styles.responsiveProduct}`}
                          >
                            <LargeProductCard
                              setShowLogin={setShowLogin}
                              setRefreshCart={setRefreshCart}
                              cartItems={cartItems}
                              product={product}
                              biller={biller}
                              onClick={() => {
                                setSelectedProduct(product);
                                setShowModal(true);
                              }}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
