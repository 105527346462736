import React, {useEffect, useState} from "react";
import styles from "../../styles/admin.module.css";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import axiosInstance from "../../../AxiosInstance";
import {DangerButton, PrimaryButton, SecondaryButton, SelectInput, TextInput} from "../../components/inputs";
import TableHeader from "../../components/table_header";
import TableComponent from "../../components/table";
import Modal from "../../components/modal/modal";
import {formatTimestamp, getOrderStatus} from "../../components/utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBackwardStep, faCheck, faEye, faForwardStep, faTrash, faX} from "@fortawesome/free-solid-svg-icons";
import {Modal as BootStrapModal} from "react-bootstrap";
import SaleDetailModal from "./sale_detail/sale_detail";
import modalstyles from "../../components/modal/modal.module.css";
import {formatPrice} from "../../../utils/utils";

export default function DynamicDayClosure () {
    const [sales, setSales] = useState([]);
    const [expenses, setExpenses] = useState([]);
    const [deliveryGuys, setDeliveryGuys] = useState([]);
    const [filteredDeliveryGuys, setFilteredDeliveryGuys] = useState([]);
    const [deliveryGuysExpenses, setDeliveryGuysExpenses] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [assignModal, setAssignModal] = useState(false);
    const [form, setForm] = useState({});
    const [dayClosure, setDayClosure] = useState({});
    const [selectedSale, setSelectedSale] = useState(null);
    const [showSale, setShowSale] = useState(false);
    const [finalCash, setFinalCash] = useState(0)
    const [showCancel, setShowCancel] = useState(false);
    const warehouse_id = parseInt(localStorage.getItem('warehouse_id'));
    const permissions = parseInt(localStorage.getItem('permissions'));

    useEffect(() => {
        axiosInstance.get('delivery_guy/').then((response) => {
            setDeliveryGuys(response.data);
        });
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            const lastSaleId = sales.length > 0 ? sales[0].id : 0;
            const selectedWarehouse = warehouse_id;
            if(selectedWarehouse === undefined) return;
            axiosInstance.get(`day_closure/get_current/?warehouse_id=${selectedWarehouse}&starting_sale_id=${lastSaleId}`).then((response) => {
                if(response.status === 200) {
                    setSales([...response.data.sales, ...sales]);
                }
            });
        }, 20000);

        return () => clearInterval(interval);
    }, [form, sales]);

    const calculateFinalCash = () => {
        let cash = dayClosure.money_start;
        sales.forEach((x) => {
            if(x.status !== 5) cash += x.sub_total;
        })
        expenses.forEach((x) => {
            cash -= x.amount;
        })
        setFinalCash(cash);
    }

    useEffect(() => {
        calculateFinalCash()
    }, [sales, dayClosure, deliveryGuysExpenses, expenses]);

    useEffect(() => {
        const selectedWarehouse = warehouse_id;

        const filtered = deliveryGuys.filter((deliveryGuy) => {return deliveryGuy.warehouse.id === selectedWarehouse});
        setFilteredDeliveryGuys(filtered);

        axiosInstance.get(`day_closure/get_current/?warehouse_id=${selectedWarehouse}`).then((response) => {
            if(response.data === undefined) return;
            setForm({
                ...form,
                day_closure_id: response.data.id
            });
            setDayClosure(response.data);
            setSales(response.data.sales);
            setDeliveryGuysExpenses(response.data.delivery_guy_expenses);
            setExpenses(response.data.delivery_closure_expenses)
        });
    }, [warehouse_id, deliveryGuys]);

    const cols = [
        { title: "Date", internal: "date", code: (data, row) => formatTimestamp(row) },
        { title: "Client", internal: "user", code: (data, row) => row?.user_data?.name + " " + row?.user_data?.lastname },
        { title: "Orders", internal: "user_sales" },
        { title: "Phone", internal: "user.user_data.phone_number" },
        { title: "District", internal: "district.name" },
        { title: "Fee", internal: "fee" },
        { title: "Total", internal: "sub_total", code:  (row, data) => {
                return formatPrice(row.sub_total + row.fee);
            } },
        { title: "Status", internal: "status", code: (data, row) => getOrderStatus(parseInt(row)) },
        { title: "Delivery Guy", internal: "delivery_guy.name" },
        { title: "Actions", internal: "warehouse", code: (row, data) => {
                if(row.status !== 5) {
                    return (
                        <div role={'button'}
                             onClick={() => {
                                 setAssignModal(true);
                                 setSelectedSale(row.id);
                             }}
                             style={{
                                 borderRadius: '6px',
                                 backgroundColor: '#009F7F',
                                 width: '120px',
                                 textAlign: 'center'
                             }} className={'py-1'}
                        >
                            <div style={{color: '#FFF'}}>{row.delivery_guy !== null ? 'Changer' : 'Assign'} livreur
                            </div>
                        </div>
                    );
                }
            }},
        { title: "Actions", internal: "warehouse", code: (row, data) => {
                //if(row.delivery_guy !== null) return (<div>Assigned</div>);
                return (
                    <div className={'d-flex justify-content-between'}>
                        {permissions === 1 &&<div role={'button'} onClick={() => backStep(row)}>
                            <FontAwesomeIcon icon={faBackwardStep} color={'#9EA3AC'}/>
                        </div>}

                        <div role={'button'} onClick={() => forwardStep(row)}>
                            <FontAwesomeIcon icon={faForwardStep} color={'#9EA3AC'}/>
                        </div>

                        <div role={'button'}
                             onClick={() => {
                                 setShowSale(true);
                                 setSelectedSale(row);
                             }}
                        >
                            <FontAwesomeIcon icon={faEye}/>
                        </div>

                        <div role={'button'} onClick={() => {
                            setSelectedSale(row);
                            setShowCancel(true);
                        }}>
                            {row.status !== 5 ? <FontAwesomeIcon icon={faX} color={'#EF4444'}/> : ''}
                        </div>
                    </div>
                );
            }},
    ];

    const expenseCols = [
        {title: 'Motive', internal: 'name'},
        {title: 'Amount', internal: 'amount'},
        {title: 'Actions', internal: 'id', code: (row, data) => {
                return (
                    <div role={'button'}
                         onClick={() => {
                             axiosInstance.delete(`day_closure_expense/${row.id}/`, form).then((response) => {
                                 window.location.reload();
                             });
                         }}
                    >
                        <FontAwesomeIcon icon={faTrash} color={'#F00'}/>
                    </div>
                )
            }}
    ];

    const deliveryCols = [
        {title: 'Name', internal: 'delivery_guy.name'},
        {title: 'Amount', internal: 'amount'},
    ];

    const createExpense = () => {
        axiosInstance.post('day_closure_expense/', form).then((response) => {
            setShowModal(false);
            window.location.reload();
        });
    }

    const assignDeliveryGuy = () => {
        axiosInstance.put(`sale/${selectedSale}/assign_delivery_guy/`, {
            delivery_guy_id: form['delivery_guy'],
            day_closure_id: form['day_closure_id']
        }).then((response) => {
            window.location.reload();
        });
    }

    const backStep = (data) => {
        axiosInstance.put('sale/' + data.id + '/previous_step/').then((response) => {
            window.location.reload()
        });
    }

    const forwardStep = (data) => {
        axiosInstance.put('sale/' + data.id + '/next_step/').then((response) => {
            window.location.reload()
        });
    }

    const cancel = () => {
        axiosInstance.post('sale/' + selectedSale?.id + '/cancel/').then((response) => {
            window.location.reload()
        });
    }

    return (
        <div className={styles.mainContainer}>
            <AdminHeader/>
            <AdminSidebar/>

            <Modal name={'Create Expense'} setter={setShowModal} show={showModal} buttonName={'Create'} onClick={createExpense}>
                <TextInput name={'name'} form={form} setForm={setForm}/>
                <TextInput name={'amount'} form={form} setForm={setForm}/>
            </Modal>

            <Modal name={'Assign Delivery Guy'} show={assignModal} setter={setAssignModal} buttonName={'Assign'} onClick={assignDeliveryGuy}>
                <SelectInput options={filteredDeliveryGuys} form={form} setForm={setForm} name={'Delivery Guy'} internalName={'delivery_guy'}/>
            </Modal>

            <BootStrapModal show={showCancel} centered={true} >
                <div className={modalstyles.header}>
                    <div>{selectedSale?.status === 5 ? "Uncancel Sale" : "Cancel Sale"}</div>
                    <div>Are you sure?</div>
                </div>

                <div className={modalstyles.footer}>
                    <SecondaryButton onClick={() => setShowCancel(false)} name={'Back'}/>
                    <div className={'ms-3'}>
                        <DangerButton name={selectedSale?.status === 5 ? 'Uncancel Sale' : "Cancel Sale"} onClick={() => cancel()}/>
                    </div>
                </div>
            </BootStrapModal>

            <BootStrapModal show={showSale} centered={true} size={'lg'}>
                <div>
                    <div style={{padding: '16px'}}>
                        <div className={'mb-2'} style={{fontWeight: '600', fontSize: '20px', color: '#334155'}}>Sale Details</div>
                        <SaleDetailModal data={selectedSale}/>
                    </div>
                    <div className={styles.footer}>
                        <div className={'ms-3'}>
                            <PrimaryButton name={'Close'} onClick={() => setShowSale(false)}/>
                        </div>
                    </div>
                </div>
            </BootStrapModal>

            <div className={`${styles.container} me-4`}>
                <TableHeader title={'Day Closing'} noCreate={true} withImport={false}/>
                <div className={'mt-3 mb-3 text-start'}>
                    <div className={'mb-1'}><b>Initial Cash: </b>{formatPrice(dayClosure.money_start)}</div>
                    <div className={'mb-1'}><b>Final Cash: </b>{formatPrice(finalCash)}</div>
                </div>
                <div style={{textAlign: 'left'}}><b>Sales</b></div>
                <div style={{overflowX: 'scroll'}}>
                    <TableComponent
                        cols={cols}
                        rows={sales}
                    />
                </div>

                <div className={'d-flex mt-4 pb-5 flex-wrap'}>
                    <div className={styles.halfContainer}>
                        <div className={'d-flex align-items-center mb-2 justify-content-between'} style={{height: '3.2rem'}}>
                            <div style={{textAlign: 'left'}}><b>List of Expenses</b></div>
                            <div><b>Total: </b>{formatPrice(expenses.reduce((a, b) => a + b.amount, 0))}</div>
                            <SecondaryButton name={'Add Expense'} onClick={() => setShowModal(true)}/>
                        </div>
                        <div style={{overflowX: 'scroll'}}>
                            <TableComponent
                                cols={expenseCols}
                                rows={expenses}
                            />
                        </div>
                    </div>
                    <div style={{flexBasis: "4%"}}/>
                    <div className={styles.halfContainer}>
                        <div className={'d-flex align-items-center mb-2 justify-content-between'} style={{height: '3.2rem'}}>
                            <div style={{textAlign: 'left'}}><b>List of Delivery Guys</b></div>
                            <div><b>Total: </b>{formatPrice(deliveryGuysExpenses.reduce((a, b) => a + b.amount, 0))}</div>
                        </div>
                        <div style={{overflowX: 'scroll'}}>
                            <TableComponent
                                cols={deliveryCols}
                                rows={deliveryGuysExpenses}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}