import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faX } from "@fortawesome/free-solid-svg-icons";
import { PrimaryButton, TextInput } from "../../../admin/components/inputs";
import { Modal as BootStrapModal } from "react-bootstrap";
import axiosInstance from "../../../AxiosInstance";
import styles from "../../../styles/login.module.css";

function LoginModule({
  setType,
  setShow,
  setPhone,
  setShouldVerify,
  shouldVerify,
  registerPhone,
}) {
  const [form, setForm] = useState({});
  const [showError, setShowError] = useState(false);

  const login = () => {
    if (form.username.length !== 9) {
      setShowError(true);
      return;
    } else {
      setShowError(false);
    }
    axiosInstance
      .post("login_phone/", {
        phone_number: form.username,
      })
      .then((response) => {
        if (response.status === 200) {
          setShouldVerify(true);
        } else {
          setPhone(form.username);
          setType("register");
        }
      });
  };

  const verify = () => {
    axiosInstance
      .post("login_phone_verification/", {
        phone_number: registerPhone ? registerPhone : form.username,
        token: form.token,
      })
      .then((response) => {
        setShowError(false);
        localStorage.clear();
        localStorage.setItem("access_token", response.data.access_token);
        axiosInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${response.data.access}`;
        window.location.replace("/locate");
      })
      .catch((err) => {
        setShowError(true);
      });
  };

  return (
    <div>
      <div
        className={"text-end"}
        role={"button"}
        onClick={() => setShow(false)}
      >
        <FontAwesomeIcon icon={faX} color={"#7F7F7F"} />
      </div>
      <div style={{ textAlign: "center" }} className={"mb-3 mt-3 mx-5"}>
        <div
          style={{ color: "#009F7F", fontSize: "x-large", fontWeight: "600" }}
        >
          Connexion
        </div>
        <div style={{ color: "#7F7F7F" }}>
          {!shouldVerify
            ? "Connectez-vous avec votre numéro de téléphone"
            : "Veuillez mettre le code reçu par SMS"}
        </div>
      </div>

      <div className={"d-flex flex-column mx-5 mb-4"}>
        {shouldVerify && (
          <TextInput
            name={"token"}
            internalName={"token"}
            form={form}
            setForm={setForm}
            hideTitle={true}
            placeholder={"SMS Token"}
          />
        )}
        {!shouldVerify && (
          <TextInput
            name={"username"}
            internalName={"username"}
            form={form}
            setForm={setForm}
            hideTitle={true}
            placeholder={"Numéro de téléphone"}
          />
        )}

        {showError && (
          <div
            style={{
              textAlign: "center",
              color: "red",
            }}
          >
            {shouldVerify
              ? "Code incorrect, veuillez réessayer."
              : "Merci d'entrer votre numéro de téléphone, ex : 771234567"}
          </div>
        )}

        <div className={"mb-3"} />
        {shouldVerify ? (
          <PrimaryButton name={"Se connecter"} onClick={() => verify()} />
        ) : (
          <PrimaryButton name={"Se connecter"} onClick={() => login()} />
        )}

        {/*shouldVerify &&
                    <div className={'d-flex justify-content-center mt-3 ps-1'}
                         style={{color: '#009f7F'}}
                         role={'button'}
                         onClick={() => {
                            login();
                         }}
                    >Resend SMS
                    </div>
                */}
      </div>
    </div>
  );
}

function RegisterModule({
  setType,
  setShow,
  phone,
  setShouldVerify,
  setRegisterPhone,
}) {
  const [form, setForm] = useState({});
  const [showError, setShowError] = useState(false);

  const register = () => {
    axiosInstance
      .post("register/", {
        user_data: {
          name: form["name"],
          lastname: form["lastname"],
          phone_number: phone,
        },
        email: form["email"],
      })
      .then((response) => {
        if (response.status === 201) {
          setShowError(false);
          setType("login");
          setShouldVerify(true);
          setRegisterPhone(phone);
        } else {
          setShowError(true);
        }
      });
  };

  return (
    <div>
      <div
        className={"text-end"}
        role={"button"}
        onClick={() => setShow(false)}
      >
        <FontAwesomeIcon icon={faX} color={"#7F7F7F"} />
      </div>
      <div style={{ textAlign: "center" }} className={"mb-3 mt-3 mx-5"}>
        <div
          style={{ color: "#009F7F", fontSize: "x-large", fontWeight: "600" }}
        >
          Inscription
        </div>
      </div>
      <div className={"d-flex flex-column mx-5"}>
        <TextInput
          form={form}
          setForm={setForm}
          name={"Nom"}
          internalName={"name"}
        />
        <div className={"mb-2"} />
        <TextInput
          form={form}
          setForm={setForm}
          name={"Prénom"}
          internalName={"lastname"}
        />
        <div className={"mb-2"} />
        <TextInput form={form} setForm={setForm} name={"email"} />
        <div className={"mb-4"} />
        <PrimaryButton name={"S'inscrire"} onClick={() => register()} />
        <div className={"mb-2"} />
        <div style={{ color: "red", textAlign: "center" }}>
          {showError && "Saisissez une adresse e-mail valide."}
        </div>
      </div>
    </div>
  );
}

function ForgotModule() {
  return <div></div>;
}

export default function LoginPopup({ show, setShow }) {
  const [type, setType] = useState("login");
  const [phone, setPhone] = useState("");
  const [shouldVerify, setShouldVerify] = useState(false);
  const [registerPhone, setRegisterPhone] = useState(null);

  useEffect(() => {
    setType("login");
  }, [show]);

  return (
    <BootStrapModal show={show} centered={true}>
      <div>
        <div style={{ padding: "16px" }}>
          {type === "login" ? (
            <LoginModule
              registerPhone={registerPhone}
              shouldVerify={shouldVerify}
              setShouldVerify={setShouldVerify}
              setPhone={setPhone}
              setType={setType}
              setShow={setShow}
            />
          ) : type === "register" ? (
            <RegisterModule
              setRegisterPhone={setRegisterPhone}
              setShouldVerify={setShouldVerify}
              phone={phone}
              setPhone={setPhone}
              setType={setType}
              setShow={setShow}
            />
          ) : (
            <ForgotModule />
          )}
        </div>
        {/*type !== 'forgot' && <div style={{backgroundColor: '#F7F7F7', color: '#009F7F', borderRadius: '10px'}} className={'py-3'}>
                    <div className={'d-flex justify-content-center'}>
                        <div>Mot de passe oublié ?</div>
                        <div className={'ps-1'} style={{fontWeight: '600'}} role={'button'} onClick={() => {setType('forgot')}}>Réinitialiser</div>
                    </div>
                </div>*/}
      </div>
    </BootStrapModal>
  );
}
