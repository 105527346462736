import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import RouteContainer from "./RouteContainer";
import { ContextProvider } from "./MyContext";
import ToastContainer from "./components/ajonjolib/toasts/toast/toast_container";
import axiosInstance from "./AxiosInstance";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    axiosInstance.get("current_user/").then((response) => {
      if (response?.response?.status === 500) {
        localStorage.removeItem("access_token");
      }
    });
  }, []);
  return (
    <div className="App">
      <ContextProvider>
        <Router>
          <RouteContainer />
        </Router>
        <ToastContainer />
      </ContextProvider>
    </div>
  );
}

export default App;
