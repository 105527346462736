import React, { useEffect, useState } from "react";
import styles from "./checkout.module.css";
import ShopHeader from "../components/header/header";
import axiosInstance from "../../AxiosInstance";
import { useLocation, useNavigate } from "react-router-dom";
import { formatPrice } from "../../utils/utils";
import {
  toast,
  ToastTypes,
} from "../../components/ajonjolib/toasts/toast/toast";

export default function Checkout() {
  const { state } = useLocation();
  const [userData, setUserData] = useState({});
  const [indications, setIndications] = useState(state.receivedIndications);
  const [canPurchase, setCanPurchase] = useState(true);
  const [showPurchaseError, setShowPurchaseError] = useState(false);
  const district = JSON.parse(localStorage.getItem("district"));
  const navigate = useNavigate();

  useEffect(() => {
    axiosInstance.get("current_user/").then((res) => {
      setUserData(res.data);
    });
  }, []);

  // l

  const makePurchase = () => {
    if (!canPurchase) return;
    axiosInstance.get("cart/is_open/").then((response) => {
      setCanPurchase(false);
      const is_open = response.data.is_open;
      const message = response.data.message;
      if (is_open) {
        axiosInstance
          .post("sale/make_sale/", {
            district_id: district.id,
            indications: indications,
          })
          .then((response) => {
            if (response.status === 200) {
              navigate("/confirmation", {
                state: {
                  userData: userData,
                  total: state.total,
                  deliveryFee: state.deliveryFee,
                },
              });
            } else {
              setShowPurchaseError(true);
              axiosInstance.post("cart/clear/").then((response) => {
                setTimeout(() => {
                  navigate("section", {});
                }, 300);
              });
            }
          })
          .catch((error) => {
            setCanPurchase(true);
            setShowPurchaseError(true);
          });
      } else {
        // toast shop is closed
        setCanPurchase(true);
        toast(message, ToastTypes.ERROR);
      }
    });
  };

  return (
    <div className={styles.container}>
      <ShopHeader />

      <div style={{ paddingTop: "80px" }}>
        <div className={styles.boxContainer}>
          <div>{userData?.user_data?.name}</div>
          <div>{userData?.user_data?.lastname}</div>
          <div>{userData?.user_data?.phone_number}</div>

          <div className={"my-4"}>{userData?.user_data?.address}</div>

          <div className={"d-flex justify-content-between"}>
            <div>Total: </div>
            <div className={"fw-bold"}>
              {formatPrice(state.total + state.deliveryFee)} FCFA
            </div>
          </div>
          <div className={"mt-2"}>
            <div className={"mb-1"}>Indications adresse:</div>
            <textarea
              value={indications}
              onChange={(e) => setIndications(e.target.value)}
              className={"w-100"}
              style={{ height: "100px" }}
            />
          </div>

          <div
            className={`${styles.orderButton} pe-2 ps-4 mt-3`}
            style={{
              cursor: canPurchase ? "pointer" : "not-allowed",
            }}
            onClick={() => makePurchase()}
          >
            <div className={"px-5 my-2"}>Confirmer ma commande</div>
          </div>
        </div>
      </div>
    </div>
  );
}
