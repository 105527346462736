import React from "react";

function formatTimestamp(timestamp) {
    if (timestamp == null) return "-";
    const date = new Date(timestamp);
    return date.toLocaleString('en-GB', { timeZone: 'UTC' });
}


function getOrderStatus(status) {
    if(status === 0) return <div style={{
        backgroundColor: '#fa9f43',
        textAlign: "center",
        borderRadius: '6px',
        color: '#FFF'
    }}>Pending</div>;
    else if(status === 1) return <div style={{
        backgroundColor: '#fae243',
        textAlign: "center",
        borderRadius: '6px',
        color: '#FFF'
    }}>In Preparation</div>;
    else if(status === 2) return <div style={{
        backgroundColor: '#439ffa',
        textAlign: "center",
        borderRadius: '6px',
        color: '#FFF'
    }}>In Delivery</div>;
    else if(status === 4) return <div style={{
        backgroundColor: '#31e523',
        textAlign: "center",
        borderRadius: '6px',
        color: '#FFF'
    }}>Completed</div>;
    else if(status === 5) return <div style={{
        backgroundColor: '#f34933',
        textAlign: "center",
        borderRadius: '6px',
        color: '#FFF'
    }}>Cancelled</div>;
    return "Unknown";
}

export {
    formatTimestamp,
    getOrderStatus
}