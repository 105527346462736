import React, {useEffect, useState} from "react";
import styles from "../../styles/admin.module.css";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import axiosInstance from "../../../AxiosInstance";
import {PrimaryButton, SelectInput, TextInput} from "../../components/inputs";

export default function SystemSettingsView () {
    const [data, setData] = useState({});
    const [form, setForm] = useState({});

    useEffect(() => {
        axiosInstance.get('system_settings/').then((response) => {
            setData(response.data);
            response.data.maintenance_mode = response.data.maintenance_mode === true ? 1 : 0;
            setForm(response.data);
        });
    }, []);

    const submit = () => {
        axiosInstance.put('system_settings/1/', form).then((response) => {
            window.location.reload();
        });
    }

    return (
        <div className={styles.mainContainer}>
            <AdminHeader/>
            <AdminSidebar/>

            <div className={`${styles.crudContainer}`}>
                <div className={styles.crudText}>
                    <div className={'mb-2'}>System Settings</div>
                    <div/>
                </div>
                <div className={styles.crudInputs}>
                    <div className={`${styles.createContainer} p-4`}>
                        <TextInput name={'Expiry Date Threshold'} form={form} setForm={setForm} internalName={'days_before_expiry_alert'}/>
                        <TextInput name={'Inventory Alert Threshold'} form={form} setForm={setForm} internalName={'days_before_inventory_alert'}/>
                        <TextInput name={'Formula Threshold'} form={form} setForm={setForm} internalName={'formula_threshold'}/>
                        <SelectInput name={'Maintenance Mode'} form={form} setForm={setForm} internalName={'maintenance_mode'} options={[{id: 0, name: 'Off'}, {id: 1, name: 'On'}]}/>
                    </div>

                    <div className={styles.buttonContainer}>
                        <PrimaryButton name={'Update'} onClick={submit}/>
                    </div>
                </div>
            </div>
        </div>
    );
}