import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBasketShopping,
  faMinus,
  faPlus,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import styles from "../../products/products.module.css";
import { Modal as BootStrapModal } from "react-bootstrap";
import { formatPrice } from "../../../utils/utils";
import {
  toast,
  ToastTypes,
} from "../../../components/ajonjolib/toasts/toast/toast";

export default function ProductModal({
  biller,
  showModal,
  setShowModal,
  selectedProduct,
  quantity,
  checkQuantity,
  addToCart,
  setSelectValues,
  selectValues,
  variantErrorId,
  locked,
}) {
  const [totalPrice, setTotalPrice] = useState(selectedProduct?.price);
  const myRef = React.useRef();

  useEffect(() => {
    if (setSelectValues) setSelectValues([]);
  }, [showModal]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (myRef.current && !myRef.current.contains(event.target)) {
        //setShowModal(false);
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleCheckboxChange =
    (min_quantity, max_quantity, variant, item_id) => (event) => {
      const { checked } = event.target;
      const found = selectedProduct.variants.filter(
        (prod_variant) => prod_variant.id === variant.id
      )[0].items;

      const selectedCountLambda = () => {
        let count = 0;
        for (const num of found) {
          if (selectValues.includes(num.id)) {
            count++;
          }
        }
        return count;
      };

      let selectedCount = selectedCountLambda();

      if (selectedCount >= max_quantity && !selectValues.includes(item_id)) {
        console.log("MAX QUANTITY");
        return;
      }

      let new_obj = [...selectValues];

      if (!selectValues.includes(item_id)) {
        console.log("insert");
        new_obj.push(item_id);
      } else {
        console.log("remove");
        const index = new_obj.indexOf(item_id);
        if (index > -1) {
          new_obj.splice(index, 1);
        }
      }

      setSelectValues(new_obj);

      let newTotalPrice = selectedProduct.price;
      Object.values(new_obj).forEach((item) => {
        const selectedItem = variant.items.find((v_item) => v_item.id === item);
        if (selectedItem && selectedItem.price) {
          newTotalPrice += selectedItem.price;
        }
      });
      setTotalPrice(newTotalPrice);
    };

  useEffect(() => {
    console.log("values");
    console.log(selectValues);
  }, [selectValues]);

  useEffect(() => {
    if (variantErrorId) {
      const variantElement = document.getElementById(
        `variant_${variantErrorId}`
      );
      variantElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  }, [variantErrorId]);

  return (
    <BootStrapModal
      show={showModal}
      centered={true}
      size={"lg"}
      onBackdropClick={() => setShowModal(false)}
      onHide={() => setShowModal(false)}
    >
      <div ref={myRef} className={`${styles.responsiveModal}`}>
        <div
          className={`${styles.showMobile} text-end`}
          role={"button"}
          onClick={() => setShowModal(false)}
        >
          <FontAwesomeIcon icon={faX} color={"#7F7F7F"} />
        </div>
        <div className={styles.responsiveModalImage}>
          <img
            alt={selectedProduct.slug}
            src={
              selectedProduct?.image
                ? selectedProduct.image.image
                : "/placeholder.jpg"
            }
            width={"100%"}
          />
        </div>
        <div
          className={`${styles.responsiveModalContainer} d-flex flex-column justify-content-between`}
        >
          <div>
            <div
              className={`${styles.hideMobile} text-end`}
              role={"button"}
              onClick={() => setShowModal(false)}
            >
              <FontAwesomeIcon icon={faX} color={"#7F7F7F"} />
            </div>
            <div
              className={"d-flex align-items-center justify-content-between"}
              style={{ fontWeight: "700" }}
            >
              <div style={{ flexBasis: "60%", fontSize: "x-large" }}>
                {selectedProduct.name}
              </div>
              <div
                style={{
                  flexBasis: "40%",
                  color: "#009F7F",
                  fontWeight: "600",
                }}
              >
                {formatPrice(
                  totalPrice
                    ? totalPrice
                    : selectedProduct?.promotion_price
                    ? selectedProduct.promotion_price
                    : selectedProduct?.price
                )}
                FCFA
              </div>
            </div>
            <div>
              <div>{selectedProduct.description}</div>
            </div>
          </div>

          <div className={"mb-3"}>
            {selectedProduct?.variants &&
              selectedProduct?.variants.length > 0 &&
              selectedProduct?.variants.map((variant, index) => (
                <div
                  key={index}
                  className={"mt-3"}
                  id={`variant_${variant.id}`}
                >
                  <div
                    className={"fw-bold fs-5"}
                    style={{
                      color: `${
                        variantErrorId === variant.id ? "red" : "black"
                      }`,
                    }}
                  >
                    {variant.name}
                  </div>
                  {variant.min_quantity === variant.max_quantity ? (
                    <div
                      style={{
                        color: `${
                          variantErrorId === variant.id ? "red" : "black"
                        }`,
                      }}
                    >
                      Choisissez {variant.min_quantity}
                    </div>
                  ) : variant.min_quantity === 0 ? (
                    <div
                      style={{
                        color: `${
                          variantErrorId === variant.id ? "red" : "black"
                        }`,
                      }}
                    >
                      Choisissez entre {variant.min_quantity} et{" "}
                      {variant.max_quantity}
                    </div>
                  ) : (
                    <div
                      style={{
                        color: `${
                          variantErrorId === variant.id ? "red" : "black"
                        }`,
                      }}
                    >
                      Choisissez entre {variant.min_quantity} et{" "}
                      {variant.max_quantity}
                    </div>
                  )}
                  {variant.description && <div>{variant.description}</div>}
                  <div aria-label="variants">
                    {variant.items.map((item) => (
                      <div key={item.id}>
                        <div style={{ borderBottom: "0.5px solid" }}>
                          <div
                            className={"py-1"}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              <strong>{item.name}</strong>
                              {item.price > 0 && (
                                <div>{formatPrice(item.price)} FCFA</div>
                              )}
                            </div>
                            <input
                              style={{
                                width: "22px",
                                height: "22px",
                              }}
                              type="checkbox"
                              name={item.id}
                              value={item.id}
                              checked={selectValues.includes(item.id)}
                              onChange={(event) =>
                                handleCheckboxChange(
                                  variant.min_quantity,
                                  variant.max_quantity,
                                  variant,
                                  item.id
                                )(event)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
          </div>

          <div className={"d-flex mx-5 d-flex justify-content-center"}>
            <div className={`${styles.counter} me-5`}>
              <FontAwesomeIcon
                icon={faMinus}
                color={"#FFF"}
                role={"button"}
                onClick={() => {
                  if (!locked) checkQuantity(-1, quantity);
                }}
              />
              <div className={"px-4"} style={{ color: "#FFF" }}>
                {quantity}
              </div>
              <FontAwesomeIcon
                icon={faPlus}
                color={"#FFF"}
                role={"button"}
                onClick={() => {
                  if (!locked) checkQuantity(1, quantity);
                }}
              />
            </div>

            <div
              className={`${styles.addToCart}`}
              onClick={() => {
                if (biller) {
                  if (biller?.is_open) addToCart();
                  else {
                    toast(
                      `${biller.name} actuellement fermé`,
                      ToastTypes.ERROR
                    );
                  }
                } else {
                  addToCart();
                }
              }}
            >
              <FontAwesomeIcon icon={faBasketShopping} className={"pe-1"} />
              <div>Ajouter</div>
            </div>
          </div>
        </div>
      </div>
    </BootStrapModal>
  );
}
