import React, {useState} from "react";
import styles from "../../styles/admin.module.css";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import TableView from "../../components/table_view";
import {formatTimestamp} from "../../components/utils";

export default function HistoricExpenseList() {
    const [rows, setRows] = useState([]);
    const cols = [
        {title: 'Name', internal: 'name'},
        {title: 'Amount', internal: 'amount'},
        {title: 'Warehouse', internal: 'warehouse.name'},
        {title: 'Time', internal: 'timestamp', code: (row, data) => {
            return formatTimestamp(data);
        }},
    ]

    return (
        <div className={styles.mainContainer}>
            <AdminHeader/>
            <AdminSidebar/>

            <div className={`${styles.container} me-4`}>
                <TableView
                    noCreate={true}
                    pull={setRows}
                    title={'Expenses'}
                    fetchUrl={'day_closure_expense'}
                    cols={cols}
                    rows={rows}
                />
            </div>
        </div>
    )
}